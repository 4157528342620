*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  border: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: transparent;
}
input[type='number'] {
  -moz-appearance: textfield;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div::-webkit-scrollbar-track {
  background-color: transparent;
  margin-bottom: 10px;
}
div::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

div::-webkit-scrollbar-thumb {
  background-color: #7c7c7c;
  border-radius: 20px;
  border: 1px solid transparent;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

textarea::-webkit-scrollbar {
  background-color: transparent;
}

textarea {
  max-height: 95px !important;
  min-height: 19px !important;
  overflow-y: auto !important;
}

#mention div div :first-child {
  max-height: 95px;
}

#scrollableDiv {
  overflow-anchor: auto !important;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

audio {
  filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
  width: 170px;
  height: 25px;
}
audio::-webkit-media-controls-mute-button {
  display: none !important;
}
audio::-webkit-media-controls-volume-slider {
  display: none !important;
}
audio::-webkit-media-controls-volume-slider-container {
  display: none !important;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin-bottom: 15px;
}

.spinner-text {
  margin-top: 10px;
  color: #333;
  text-align: center;
  display: block;
}