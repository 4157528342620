.k-panelbar {
  z-index: 2 !important;
}

.k-panelbar.k-pos-relative {
    border-radius: 5px !important;
}

.k-panelbar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link {
    color: black !important;
    border-radius: 5px 5px 0 0;
}

.k-panelbar > .k-item > .k-link.k-selected, .k-panelbar > .k-panelbar-header > .k-link.k-selected {
    background: #129fdb !important;
    color: white !important;
    border-radius: 5px 5px 0 0;

}


.k-panelbar > .k-item > .k-link.k-selected, .k-panelbar > .k-panelbar-header > .k-link.k-selected {
  background: #ffffff !important;
  border-radius: 5px 5px 0 0;
  color: black !important;
}

button.k-button.k-button-md.k-button-flat.k-button-flat-primary {
  color: #515151 !important;
}

.k-button-outline-primary {
  border: 1px solid #129fdb !important;
  color: black !important;
}

.k-button-outline-primary:hover {
  background: #129fdb !important;
  border: 1px solid #129fdb !important;
}

.k-button-solid-primary {
  background: #129fdb !important;
  border: 1px solid #129fdb5c  !important;
}

.k-button-solid-primary:hover {
  border-color: #178ec1 !important;
  background-color: #178ec1 !important;
}

.k-checkbox:checked, .k-checkbox.k-checked {
  border: none  !important;
  padding: 5px !important ;
}

.k-panelbar .k-group > .k-item > .k-link.k-selected, .k-panelbar .k-group > .k-panelbar-item > .k-link.k-selected, .k-panelbar .k-panelbar-group > .k-item > .k-link.k-selected, .k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-selected {
  background-color: #0c213a !important;
}

.k-panelbar .k-group > .k-item > .k-link.k-selected:hover, .k-panelbar .k-group > .k-item > .k-link.k-selected.k-hover, .k-panelbar .k-group > .k-panelbar-item > .k-link.k-selected:hover, .k-panelbar .k-group > .k-panelbar-item > .k-link.k-selected.k-hover, .k-panelbar .k-panelbar-group > .k-item > .k-link.k-selected:hover, .k-panelbar .k-panelbar-group > .k-item > .k-link.k-selected.k-hover, .k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-selected:hover, .k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-selected.k-hover {
  background-color: #071422 !important;
}

